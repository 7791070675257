import type { StateCreator } from 'zustand';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type WalletSessionStore = {
  hasWalletAccess?: boolean;
  setHasWalletAccess: (hasWalletAccess: boolean) => void;

  refreshToken?: string;
  setRefreshToken: (refreshToken: string) => void;
};

export const walletSessionStore: StateCreator<WalletSessionStore, [], [], WalletSessionStore> = set => ({
  setHasWalletAccess: (hasWalletAccess?: boolean) => set(() => ({ hasWalletAccess })),
  setRefreshToken: (refreshToken?: string) => set(() => ({ refreshToken })),
});

export const useWalletSessionStore = create<WalletSessionStore>()(
  persist(walletSessionStore, {
    name: 'wallet-session-store',
    getStorage: () => sessionStorage,
  })
);
