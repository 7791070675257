import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import React from 'react';
import cn from 'classnames';

export const Layout = withComponentErrorBoundary(LayoutComp);

type DirectionType = 'row' | 'column' | 'row-reverse' | 'column-reverse';
type AlignType = 'start' | 'center' | 'end' | 'space-around' | 'space-between' | 'space-evenly';
type PerpendicularType = 'start' | 'center' | 'end' | 'stretch';

export type ILayoutProps = UIComponent & {
  direction?: DirectionType;
  align?: AlignType;
  perpendicular?: PerpendicularType;
  gap?: number;
};

function LayoutComp({
  direction = 'row',
  align = 'start',
  perpendicular = 'stretch',
  gap,
  className,
  children,
  ...otherProps
}: ILayoutProps) {
  const bem = useBemCN('layout');

  const directionClass = useLayoutDirectionClassNames(direction);
  const alignClass = useLayoutAlignClassNames(align);
  const perpendicularClass = useLayoutPerpendicularClassNames(perpendicular);

  const finalClassName = cn(directionClass, alignClass, perpendicularClass, gap ? `tw-gap-${gap}` : null, className);

  return (
    <section {...otherProps} {...bem(null, null, finalClassName)}>
      {children}
    </section>
  );
}

function useLayoutDirectionClassNames(direction: DirectionType) {
  const flexDirectionString = React.useMemo(() => {
    switch (direction) {
      case 'row':
        return 'tw-flex-row';
      case 'column':
        return 'tw-flex-col';
      case 'row-reverse':
        return 'tw-flex-row-reverse';
      case 'column-reverse':
        return 'tw-flex-col-reverse';
    }
  }, [direction]);

  return ['tw-flex', flexDirectionString];
}

function useLayoutAlignClassNames(align: AlignType) {
  return React.useMemo(() => {
    switch (align) {
      case 'start':
        return ['tw-justify-start'];
      case 'center':
        return ['tw-justify-center'];
      case 'end':
        return ['tw-justify-end'];
      case 'space-around':
        return ['tw-justify-around'];
      case 'space-between':
        return ['tw-justify-between'];
      case 'space-evenly':
        return ['tw-justify-evenly'];
    }
  }, [align]);
}

function useLayoutPerpendicularClassNames(perpendicular: PerpendicularType) {
  return React.useMemo(() => {
    switch (perpendicular) {
      case 'start':
        return ['tw-items-start', 'tw-content-start'];
      case 'center':
        return ['tw-items-center', 'tw-content-center', 'tw-max-w-full'];
      case 'end':
        return ['tw-items-end', 'tw-content-end'];
      case 'stretch':
        return ['tw-items-stretch', 'tw-content-stretch'];
    }
  }, [perpendicular]);
}
