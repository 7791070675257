import type { IWalletTokenRefreshResponse } from '@gik/platform-management-api-js';
import axios from 'axios';
import { useWalletSessionStore } from '../../../store/WalletSessionstore';
import jwtDecode from 'jwt-decode';
import { useUserStore } from '../../../store/UserStore';
import { useWalletRedemptionStore } from '../../../store/WalletRedemptionStore';

// register wallet auth interceptor
export const walletRefreshTokenInterceptor = async _config => {
  const newConfig = _config;

  // if (localStorage) {
  //   const storeValue = localStorage.getItem('user-v2');
  //   const walletSessionStoreRaw = sessionStorage.getItem('wallet-session-store');
  //   const walletStoreStr = localStorage.getItem('wallet-redemption-store');

  //   const setRefreshToken = useWalletSessionStore.getState().setRefreshToken;

  //   if (storeValue) {
  //     const store = JSON.parse(storeValue);
  //     const walletStore = JSON.parse(walletStoreStr);
  //     const walletSessionStore = walletSessionStoreRaw ? JSON.parse(walletSessionStoreRaw) : undefined;

  //     const customerId = walletStore?.state?.customerId;

  //     // const customerId = walletSessionStore?.state?.customerId;

  //     const refreshToken = store?.state?.refreshToken;
  //     const walletRefreshToken = walletSessionStore?.state?.refreshToken;

  //     // stop if there we don't have a token
  //     if (!walletRefreshToken) return newConfig;

  //     const walletRefreshTokenDecoded = jwtDecode(walletRefreshToken);

  //     const issuedDate = parseInt(walletRefreshTokenDecoded.issued) * 1000;

  //     const now = new Date(); // Current time
  //     const elapsedMs = now - issuedDate; // Difference in milliseconds
  //     const elapsedMinutes = Math.floor(elapsedMs / (1000 * 60)); // Convert to minutes

  //     const expDate = parseInt(walletRefreshTokenDecoded.exp) * 1000;

  //     const expMs = expDate - now; // Difference in milliseconds
  //     const expMinutes = Math.floor(elapsedMs / (1000 * 60)); // Convert to minutes

  //     console.log('expMinutes', expMinutes);
  //     // stop if token was refreshed recently
  //     // if (elapsedMinutes < 2) return newConfig;

  //     // stop if token will not expire soon
  //     if (expMinutes > 5) return newConfig;

  //     const dotnetClient = axios.create({
  //       baseURL: process.env.DOTNET_API_URL,
  //       httpsAgent: true,
  //       withCredentials: false,
  //     });

  //     try {
  //       const response = await dotnetClient.post<IWalletTokenRefreshResponse>('/authentication/wallet-session-extend', {
  //         refreshToken,
  //         walletRefreshToken,
  //         customerId,
  //       });

  //       setRefreshToken(response.data.accessToken);
  //     } catch (error) {
  //       console.error('failed to call wallet-refresh', error);

  //       setRefreshToken(undefined);
  //     }
  //   }
  // }

  const setRefreshToken = useWalletSessionStore.getState().setRefreshToken;

  const refreshToken = useUserStore?.getState().refreshToken;
  const walletRefreshToken = useWalletSessionStore?.getState().refreshToken;
  const customerId = useWalletRedemptionStore?.getState().customerId;

  let walletRefreshTokenDecoded;

  if (!walletRefreshToken) return newConfig;

  try {
    walletRefreshTokenDecoded = jwtDecode(walletRefreshToken);
  } catch (err) {
    console.log('EMPTY TOKEN 0', walletRefreshToken, err);
    setRefreshToken(undefined);
    return newConfig;
  }

  const issuedDate = parseInt(walletRefreshTokenDecoded.issued) * 1000;

  const now = new Date(); // Current time
  const elapsedMs = now - issuedDate; // Difference in milliseconds
  const elapsedMinutes = Math.floor(elapsedMs / (1000 * 60)); // Convert to minutes

  const expDate = parseInt(walletRefreshTokenDecoded.exp) * 1000;

  const expMs = expDate - now; // Difference in milliseconds
  const expMinutes = Math.ceil(expMs / (1000 * 60)); // Convert to minutes

  if (refreshToken && expMinutes <= 0) {
    console.info('Token Exired: emptying session storage');
    setRefreshToken(undefined);
    return newConfig;
  }

  const dotnetClient = axios.create({
    baseURL: process.env.DOTNET_API_URL,
    httpsAgent: true,
    withCredentials: false,
  });

  if (walletRefreshToken) {
    try {
      const response = await dotnetClient.post<IWalletTokenRefreshResponse>('/authentication/wallet-session-verify', {
        refreshToken,
        walletRefreshToken,
        customerId,
      });

      // if (response.status === HttpStatusCode.Ok) {
      //   setRefreshToken(response.data.accessToken);
      // }
    } catch (error) {
      console.error('failed to call wallet-refresh', error);

      setRefreshToken(undefined);
    }
  }

  return newConfig;
};
